import React from "react";


function CountBanner() {
    return (
        <section id="sweatHeader">

            <p className="text-pink" id="sweatTextLeft">How many</p>
            <p className="text-pink" id="sweatTextRight">did you do?</p>

        </section>
    )
}


export default CountBanner